.container {
    width: 22.1875%;
    height: 150px;
}

.image {
    display: block;
    object-fit: cover;

    width: 100%;
    height: 100%;

    cursor: pointer;
}