.button {
    composes: inlineFlexRow alignItemsCenter from global;

    height: 40px;

    margin: 0;
	padding: 0 15px;

    border: none;
    border-radius: 6px;

    outline: none;

    font-weight: 700;
    text-transform: none;

    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: pointer;
	overflow: hidden;
}

.button:focus {
    outline: none;
}

.mwNromal {
    max-width: 200px;
}

.mwWide {
    max-width: 300px;
}

.buttonPrimary {
    composes: button;
    background-color: var(--color-accent);
    color: var(--contrast-color-accent);
}

.buttonPrimary:hover {
    background-color: var(--color-accent-light);
    color: var(--contrast-color-accent-light);
}

.buttonPrimary:disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.buttonSecondary {
    composes: button;
    background-color: transparent;
}

.buttonSecondary:hover {
    background-color: var(--color-accent-light);
    color: var(--contrast-color-accent-light);
}

.buttonSecondary:disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.svg {
    composes: noShrink from global;
    fill: currentColor;
    margin-left: -10px;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
}