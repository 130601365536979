.container {
    position: relative;

    max-width: 80vw;
    max-height: 80vh;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.label {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
    cursor: pointer;
}

.label:hover {
    opacity: 0.8;
}

.labelInActive {
    composes: label;
    opacity: 0.4;
}

.labelFat {
    composes: label;

    font-size: 14px;
    padding: 4px 6px;
    cursor: initial;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.topBar {
    composes: flexRow alignItemsCenter from global;

    position: absolute;
    top: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.deleteButton {
    position: absolute;

    right: 0px;

    color: white;
    background-color: transparent;
}

.deleteButton:hover {
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.bottomBar {
    composes: flexRow alignItemsCenter from global;

    position: absolute;
    bottom: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------- stuff -- */

.wrappedImage {
    max-width: 80vw;
    max-height: 80vh;
}

.hidden {
    display: none;
}

.overlayProgress {
    composes: flexCol justifyContentCenter alignItemsCenter from global;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}