.container {
    composes: flexRow alignItemsCenter noShrink from global;

    width: 100%;
    height: 70px;

    padding: 0 30px;

    border-radius: 35px;
    /* border: solid 2px var(--color-placeholder); */

    background-color: #ffffff;
}

.svg {
    margin-right: 10px;
}

.input {
    flex: 1;
    border: none;
    outline: none;
}

.input::placeholder {
    color: var(--color-placeholder);
}