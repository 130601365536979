@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
/* TODO: HAX, find a better solution later */
.react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}

:root {
  --accent: #4400ff;
  --background: #EDECF0;
  --hover: #E1DFE6;
  --placeholder: rgba(0,0,0,.33);
  --interactive: #000;
  --text: #444;
  --navHeight: 70px;
  --fontSize: 16px;
}

body {
  background-color: #EDECF0;
  background-color: var(--background);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-size: var(--fontSize);
  font-weight: 400;
}

/* Buttons */

/* button {
  font-weight: 700;
  background-color: var(--accent);
  color: #fff;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
}

button:disabled {
  background-color: transparent;
  color: #000;
  cursor: not-allowed;
}

button:focus, input:focus, textarea:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px var(--hover);
}

button.secondary {
  color: var(--accent);
  background-color: transparent;
}

button.secondary:hover {
  background-color: var(--hover);
} */

.dh-container {
    width: 100%;
    max-width: 760px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

#container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#container a {
  margin: .5em;
}
#container a:first-of-type {
  margin-left: 1em;
}
#container a:last-of-type {
  margin-right: 1em;
}
#spacer {
  flex: 1 1;
}

/* search & patient search */

.search {
  border: none;
  width: 800px;
  line-height: 70px;
  background-color: #fff;
  border-radius: 35px;
  text-indent: 70px;
}

.search::-webkit-input-placeholder {
  text-indent: calc(50% - 70px);
  -webkit-transition: text-indent 200ms ease-out;
  transition: text-indent 200ms ease-out;
  color: rgba(0,0,0,.33);
  color: var(--placeholder);
}

.search::-moz-placeholder {
  text-indent: calc(50% - 70px);
  -webkit-transition: text-indent 200ms ease-out;
  transition: text-indent 200ms ease-out;
  color: rgba(0,0,0,.33);
  color: var(--placeholder);
}

.search::-ms-input-placeholder {
  text-indent: calc(50% - 70px);
  -webkit-transition: text-indent 200ms ease-out;
  transition: text-indent 200ms ease-out;
  color: rgba(0,0,0,.33);
  color: var(--placeholder);
}

.search::placeholder {
  text-indent: calc(50% - 70px);
  -webkit-transition: text-indent 200ms ease-out;
  transition: text-indent 200ms ease-out;
  color: rgba(0,0,0,.33);
  color: var(--placeholder);
}

.search:focus::-webkit-input-placeholder {
  text-indent: inherit;
  color: rgba(0,0,0,.2);
}

.search:focus::-moz-placeholder {
  text-indent: inherit;
  color: rgba(0,0,0,.2);
}

.search:focus::-ms-input-placeholder {
  text-indent: inherit;
  color: rgba(0,0,0,.2);
}

.search:focus::placeholder {
  text-indent: inherit;
  color: rgba(0,0,0,.2);
}

/* Breadcrumbs */

.breadcrumb-item:only-of-type {
  display: none;
}

/* Patient list representation */

#patients, #records {
  width: 100%;
}

#empty {
  margin-top: 120px;
  margin-left: 70px;
  color: #444;
  color: var(--text);
}

#patients a:hover {
  text-decoration: none;
}

#patients ol {
  list-style: none;
  width: 800px;
  padding: 0;
  margin: 0;
  margin-top: calc(70px + 50px);
  margin-top: calc(var(--navHeight) + 50px);
}

.patient {
  background-color: #fff;
  border-radius: 5px;
  width: 730px;
  min-height: 70px;
  margin-left: 30px;
  margin-bottom: 20px;
  padding: 20px 110px 20px 40px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.patient:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.patient h2 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
  margin: 0;
}

.patient p {
  color: #444;
  color: var(--text);
  margin: 0;
}

/* Patients search bar */

.prominent-search {
  position: absolute;
  margin: 0 auto;
}

.prominent-search button {
  position: absolute;
  top: 15px;
  right: 40px;
}

.prominent-search .icon {
  position: absolute;
  left: 30px;
  top: 20px;
}

/* NavBar */

.navbar {
  padding: 0;
}

.navbar nav {
  display: grid;
  width: 100%;
  grid-template-columns: 70px 1fr -webkit-max-content;
  grid-template-columns: 70px 1fr max-content;
  grid-template-columns: var(--navHeight) 1fr -webkit-max-content;
  grid-template-columns: var(--navHeight) 1fr max-content;
  grid-auto-rows: 70px;
  grid-auto-rows: var(--navHeight);
  background-color: #fff;
  padding: 0;
  box-shadow: 0 0 8px 0 #ccc;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom: solid 2px transparent;
}

.navbar-nav {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.nav-item {
  flex-grow: 1;
  text-align: center;
  height: 70px;
  height: var(--navHeight);
}

.navbar-brand {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}

.navbar-brand {
  margin: 0 auto;
}

.navbar-collapse {
  margin: 15px;
  grid-column: 3;
}

/* tabber */

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
  box-sizing: border-box;
}

.nav-item a {
  color: #000;
  color: var(--interactive);
  width: 100%;
  text-decoration: none;
}

.nav-item:hover {
  background-color: #E1DFE6;
  background-color: var(--hover);
}

.nav-item a.active {
  color: #4400ff;
  color: var(--accent);
  border-bottom: solid 2px #4400ff;
  border-bottom: solid 2px var(--accent);
}

.nav-item a.active:hover {
  background-color: transparent;
}

/* breadcrumbs */

.breadcrumb  {
  margin-top: 50px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: .75em;
}

.breadcrumb a {
  color: black;
}

.breadcrumb span.active {
  color: #4400ff;
  color: var(--accent);
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #95a5a6;
    content: "›";
}

/* centering */

#loggedInUser, .navbar-brand, .nav-item {
  display: flex;
  align-items: center;
  text-align: center;
}

.navbar-brand {
  justify-content: center;
}

.navbar-brand, .nav-item a, .username {
  line-height: 70px;
  line-height: var(--navHeight);
}

/* Logged in component*/

#loggedInUser {
  margin-right: 30px;
}

.userimage {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fafafa;
  background-size: cover;
  margin: 0 10px;
  background-position: center;
}

.username {
  cursor: pointer;
  padding: 0;
}

#loggedInUser:hover {
  background-color: #E1DFE6;
  background-color: var(--hover);
}

.account-dropdown {
  position: absolute;
  right: 30px;
  top: 70px;
  top: var(--navHeight);
  padding: 0;
  background-color: #fff;
  box-shadow: 0 12px -2px 0 #ccc;
  overflow: hidden;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transition: -webkit-transform 150ms ease-out;
  transition: -webkit-transform 150ms ease-out;
  transition: transform 150ms ease-out;
  transition: transform 150ms ease-out, -webkit-transform 150ms ease-out;
}

.account-dropdown li {
  list-style: none;
  background-color: #fff;
  width: auto;
  padding: 20px 60px;
  cursor: pointer;
}

.account-dropdown li:hover {
  background-color: #E1DFE6;
  background-color: var(--hover);
}

.account-dropdown.collapsed {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  pointer-events: none;
}

#loggedInUser .icon {
  -webkit-transition: -webkit-transform 150ms ease-out;
  transition: -webkit-transform 150ms ease-out;
  transition: transform 150ms ease-out;
  transition: transform 150ms ease-out, -webkit-transform 150ms ease-out;
}

#loggedInUser.collapsed .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* Modal */

#newPatientForm label {
  margin-top: 30px;
  display: block;
}

#newPatientForm textarea, #newPatientForm input[type="text"] {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 30px 30px;
}

#newPatientForm input[type="text"] {
  height: 70px;
}

#newPatientForm .actions {
  text-align: center;
}

#newPatientForm button {
  margin: 20px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  background-color: #EDECF0 !important;
  background-color: var(--background) !important;
  padding: 40px !important;
  /* DEV Note - KILL REACT MODAL */
}

/* Records table */

.react-bs-table-container {
  width: 100%;
  margin-top: 140px;
}

/* Image upload quick hack - to be refined */

#imageUpload button:disabled {
  background-color: #E1DFE6;
  background-color: var(--hover);
}

#newLesion {
  margin-top: 40px;
}

#react-quiz-container .questionWrapper .btn {
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 2px;
  border: 1px solid #e8e8e8;;
  font-size: 12px;
  display: block;
  white-space: normal;
  text-align: unset;
  padding: 5px 6px;
}

#react-quiz-container .selection-tag,
#react-quiz-container .number-of-selection{
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
}

#react-quiz-container .questionWrapper .btn.nextQuestionBtn {
  background: blue;
  color: white;
}

/* TODO: Currently unused. I left it here until we agree on scroll behaviour */
.ScreenContent_container__9_4C6 {

    height: var(--screen-content-height);
    max-height: var(--screen-content-height);
    width:  100%;
    max-width: var(--max-width);

    margin: 0 auto;
    overflow: auto;
}

.ScreenContent_outerContainer__3ZABb {
    height: var(--screen-content-height);
    max-height: var(--screen-content-height);
    width:  100%;
    overflow: auto;
}

.ScreenContent_innerContainer__Gnh0Z {
    max-width: var(--max-width);
    margin: 0 auto;
}
.SearchField_container__BQxAq {

    width: 100%;
    height: 70px;

    padding: 0 30px;

    border-radius: 35px;
    /* border: solid 2px var(--color-placeholder); */

    background-color: #ffffff;
}

.SearchField_svg__1Rflr {
    margin-right: 10px;
}

.SearchField_input__1omhg {
    flex: 1 1;
    border: none;
    outline: none;
}

.SearchField_input__1omhg::-webkit-input-placeholder {
    color: var(--color-placeholder);
}

.SearchField_input__1omhg::-moz-placeholder {
    color: var(--color-placeholder);
}

.SearchField_input__1omhg::-ms-input-placeholder {
    color: var(--color-placeholder);
}

.SearchField_input__1omhg::placeholder {
    color: var(--color-placeholder);
}
.ItemList_container__1B5Lx {

}

.ItemList_scrollContainer__GUj1c {
    /* overflow: auto; */
}

.ItemList_addItemButton___K_ZV {

    width: 100%;
    height: 70px;

    margin-bottom: 20px;
    padding: 0 40px;

    border-radius: 6px;
    border: dashed 2px #ffffff;

    outline: none;

    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);

    -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition: -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ItemList_addItemButton___K_ZV:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.ItemList_svg__2S0jK {
    margin-left: -10px;
}

.PatientListItem_container__34f5G {
    margin-bottom: 20px;
    padding: 20px 40px;

    border-radius: 6px;
    background-color: #ffffff;

    text-decoration: none;
}

.PatientListItem_nameText__34tzD {
    font-weight: bold;
    color: var(--color-text-dark);
    margin-bottom: 10px;
}

.PatientListItem_descriptionText__2gsdM {
    color: var(--color-text);
}

.PatientListItem_link__2nMFR {
    display: block;
    text-decoration: none;
    color: currentColor;

    -webkit-transition:
        -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.PatientListItem_link__2nMFR:hover {
    text-decoration: none;
    color: currentColor;

    -webkit-transform: scale(1.02);

            transform: scale(1.02);
}
.PatientList_searchField__1aXWp {
    margin: 70px 0 50px 0;
}

.PatientList_patientList__22x0P {
    padding: 0 30px;
}
.ScreenTitle_title__27-K9 {
    font-size: 3rem;
    font-weight: bold;

    margin: 60px 0 20px 0;
    text-indent: var(--screen-title-indent);
}
.ScreenControls_controlsContainer__2OgAP {
    padding-left: var(--screen-title-indent);
}
.Button_button__3UQR5 {

    height: 40px;

    margin: 0;
	padding: 0 15px;

    border: none;
    border-radius: 6px;

    outline: none;

    font-weight: 700;
    text-transform: none;

    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: pointer;
	overflow: hidden;
}

.Button_button__3UQR5:focus {
    outline: none;
}

.Button_mwNromal__3CNxv {
    max-width: 200px;
}

.Button_mwWide__3jATL {
    max-width: 300px;
}

.Button_buttonPrimary__3ZyYJ {
    background-color: var(--color-accent);
    color: var(--contrast-color-accent);
}

.Button_buttonPrimary__3ZyYJ:hover {
    background-color: var(--color-accent-light);
    color: var(--contrast-color-accent-light);
}

.Button_buttonPrimary__3ZyYJ:disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.Button_buttonSecondary__1-cZ7 {
    background-color: transparent;
}

.Button_buttonSecondary__1-cZ7:hover {
    background-color: var(--color-accent-light);
    color: var(--contrast-color-accent-light);
}

.Button_buttonSecondary__1-cZ7:disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.Button_svg__3JNHC {
    fill: currentColor;
    margin-left: -10px;
}

.Button_text__u2NB2 {
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
}
.Breadcrumb_breadcrumbContainer__3bdP8 {
    height: 40px;
}
.RecordList_headerContainer__1_3mc {

    min-height: 30px;
    padding: 0 40px;

    font-weight: bold;
}

.RecordList_link__pG9nU {
    display: block;
    text-decoration: none;
    color: currentColor;

    -webkit-transition:
        -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.RecordList_link__pG9nU:hover {
    text-decoration: none;
    color: currentColor;

    -webkit-transform: scale(1.02);

            transform: scale(1.02);
}

.RecordList_itemContainer__2P_7A {

    margin-bottom: 20px;
    padding: 20px 40px;

    border-radius: 6px;
    background-color: #ffffff;

    text-decoration: none;
}

.RecordList_col__1baPT {
    margin-right: 20px;
}

.RecordList_col__1baPT:last-child {
    margin-right: 0;
}

.RecordList_colS__1jDjM {
    width: 120px;
}

.RecordList_colIcon__3fbMN {
    width: 120px;
}

.RecordList_colGrow__VoNc3 {
    flex: 1 0;
}

.RecordList_svg__15crl {
    fill: currentColor;
}
.Patient_controlsContainer__3ijyV {
    margin-bottom: 100px;
}

.Patient_searchField__OG8em {
    margin-bottom: 20px;
}

.Patient_recordListHeader__1hZI9 {
    margin-bottom: 20px;
}

.Patient_recordList__1Ynux {
    padding: 0 30px;
}

.PatientDetails_controlsContainer__19Oz1 {
    margin-bottom: 120px;
}

.PatientDetails_button__qptq3 {
    margin-right: 20px;
}

.PatientDetails_button__qptq3:last-child {
    margin-right: 0;
}

.PatientDetails_label__2hlOB {
    color: var(--color-text);
    margin-bottom: 20px;
    text-indent: 40px;
}

.PatientDetails_textarea__2mNgd {

	height: 90px;
    max-height: 300px;

	border: solid 2px var(--color-placeholder);
    border-radius: 6px;

    padding: 20px 40px;
    margin-bottom: 30px;

    font-weight: 700;
    resize: vertical;
    outline: none;
}

.PatientDetails_textarea__2mNgd::-webkit-input-placeholder {
	color: var(--color-placeholder);
}

.PatientDetails_textarea__2mNgd::-moz-placeholder {
	color: var(--color-placeholder);
}

.PatientDetails_textarea__2mNgd::-ms-input-placeholder {
	color: var(--color-placeholder);
}

.PatientDetails_textarea__2mNgd::placeholder {
	color: var(--color-placeholder);
}
.RadioButton_variables__12Rfb {
	--timing: 200ms;
	--easing: cubic-bezier(0.165, 0.84, 0.44, 1);
	--hover: .33;
}

.RadioButton_container__1S9Bj {
    position: relative;
}

.RadioButton_checkbox__10QYH {
    position: absolute;
    left: -9999px;
}

.RadioButton_label__2fIci {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 30px;
    display: inline-block;
    font-weight: bold;
}

.RadioButton_label__2fIci::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 8px;
    width: 14px;
    height: 14px;
   	box-shadow: 0 0 0 2px var(--color-interactive) inset;
    border-radius: 100%;
}

.RadioButton_checkbox__10QYH:checked + .RadioButton_label__2fIci::after {
	box-shadow: 0 0 0 4px var(--color-accent) inset;
}

.RadioButton_label__2fIci:hover,
.RadioButton_checkbox__10QYH:focus + .RadioButton_label__2fIci {
	color: var(--color-accent);
}

.RadioButton_label__2fIci::before {
	display: inline-block;
	position: absolute;
	top: 5px;
	left: 3px;
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: var(--color-accent);
	opacity: 0;
}

.RadioButton_checkbox__10QYH:not(:checked)

.RadioButton_checkbox__10QYH:not(:checked) + .RadioButton_label__2fIci:hover:after,
.RadioButton_checkbox__10QYH:not(:checked):focus + .RadioButton_label__2fIci:after {
	box-shadow: 0 0 0 2px var(--color-accent) inset;
}

.RadioButton_label__2fIci:hover:before,
.RadioButton_checkbox__10QYH:focus + .RadioButton_label__2fIci:before {
	opacity: var(--hover);
}

.RadioButton_label__2fIci::after,
.RadioButton_label__2fIci::before,
.RadioButton_label__2fIci {
	-webkit-transition-duration: var(--timing);
	        transition-duration: var(--timing);
	-webkit-transition-timing-function: var(--easing);
	        transition-timing-function: var(--easing);
}

.RadioButton_label__2fIci:after {
	-webkit-transition-property: box-shadow;
	transition-property: box-shadow;
}

.RadioButton_label__2fIci:before {
	-webkit-transition-property: opacity;
	transition-property: opacity;
}

.RadioButton_label__2fIci {
	-webkit-transition-property: color;
	transition-property: color;
}
.ImageGaleryItem_container__2VF5s {
    width: 22.1875%;
    height: 150px;
}

.ImageGaleryItem_image__2egwM {
    display: block;
    object-fit: cover;

    width: 100%;
    height: 100%;

    cursor: pointer;
}
.FullscreenGalery_container__2KS7T {
    position: fixed;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999999;
}

.FullscreenGalery_image__3JjBk {
    max-width: 80vw;
    max-height: 80vh;
}

.FullscreenGalery_button__2dY1R {
    position: absolute;
    min-width: 50px;
    min-height: 50px;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.FullscreenGalery_button__2dY1R:hover {
    background-color: rgba(255, 255, 255, 1);
}

.FullscreenGalery_nextButton__21J-8 {
    right: 20px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.FullscreenGalery_prevButton__3rmBj {
    left: 20px;
}

.FullscreenGalery_closeButton__3_FLq {
    right: 20px;
    top: 20px;
}
.ImageGalery_container__1-IRh {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ImageGalery_image__1Fa1G {
    display: block;
    object-fit: cover;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.ImageGalery_image__1Fa1G:nth-child(4n) {
    margin-right: 0px;
}
.withControlsOverlay_overlayContainer__23NUy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ progress overlay -- */

.withControlsOverlay_overlayProgress__1Rjiq {
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ controls overlay -- */

.withControlsOverlay_overlayControls__1w4Kq {
    cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.withControlsOverlay_label__7VMRJ {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

.withControlsOverlay_label__7VMRJ:hover {
    opacity: 0.8;
}

.withControlsOverlay_labelInActive__38ILA {
    opacity: 0.4;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.withControlsOverlay_topBar__32-Mr {

    padding: 5px 10px;
    width: 100%;
    min-height: 30px;
}

.withControlsOverlay_deleteButton__3BgHs {
    position: absolute;

    width: 30px;
    height: 30px;

    top: 5px;
    right: 10px;

    display: none;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.withControlsOverlay_overlayContainer__23NUy:hover .withControlsOverlay_deleteButton__3BgHs {
    display: block;
}

.withControlsOverlay_overlayContainer__23NUy:hover .withControlsOverlay_deleteButton__3BgHs:hover {
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.9);
}

.withControlsOverlay_svg__3BbYS {
    fill: currentColor;
}

.withControlsOverlay_prediction__1MFgM {
}

.withControlsOverlay_overlayContainer__23NUy:hover .withControlsOverlay_prediction__1MFgM {
    display: none;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.withControlsOverlay_bottomBar__3gseL {

    padding: 5px 10px;

    width: 100%;
}

.withDetailsOverlay_overlayContainer__2x_Oi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ progress overlay -- */

.withDetailsOverlay_overlayProgress__3JTs2 {
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ controls overlay -- */

.withDetailsOverlay_overlayControls__3F9q8 {
    cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.withDetailsOverlay_label__T0xLw {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.withDetailsOverlay_topBar__2cM3Y {

    padding: 5px 10px;
    width: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.withDetailsOverlay_bottomBar__1r1lU {

    padding: 5px 10px;
    width: 100%;
}

.withImageDetails_container__3zrro {
    position: relative;

    max-width: 80vw;
    max-height: 80vh;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.withImageDetails_label__3CRMB {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

.withImageDetails_labelFat__30PRE {

    font-size: 14px;
    padding: 4px 6px;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.withImageDetails_topBar__1MCt6 {

    position: absolute;
    top: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.withImageDetails_prediction__3W0FR {
    color: white;
    margin-right: 20px;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.withImageDetails_bottomBar__1dfgi {

    position: absolute;
    bottom: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.withImageDetails_wrappedImage__3_JfK {
    max-width: 80vw;
    max-height: 80vh;
}
.withImageControls_container__1WG2y {
    position: relative;

    max-width: 80vw;
    max-height: 80vh;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.withImageControls_label__3ubFt {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
    cursor: pointer;
}

.withImageControls_label__3ubFt:hover {
    opacity: 0.8;
}

.withImageControls_labelInActive__1k885 {
    opacity: 0.4;
}

.withImageControls_labelFat__2GqYl {

    font-size: 14px;
    padding: 4px 6px;
    cursor: auto;
    cursor: initial;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.withImageControls_topBar__2Hq4w {

    position: absolute;
    top: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.withImageControls_deleteButton__3mojK {
    position: absolute;

    right: 0px;

    color: white;
    background-color: transparent;
}

.withImageControls_deleteButton__3mojK:hover {
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.withImageControls_bottomBar__3QZRw {

    position: absolute;
    bottom: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------- stuff -- */

.withImageControls_wrappedImage__3CZcI {
    max-width: 80vw;
    max-height: 80vh;
}

.withImageControls_hidden__3WGDX {
    display: none;
}

.withImageControls_overlayProgress__3xVsS {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}
.ImageItem_image__22rOS {
    display: block;
    object-fit: contain;

    width: 100%;
    height: 100%;
}
.LesionRecordItem_container__1Fg4K {
    padding: 20px 40px 0px 40px;
    margin-bottom: 30px;

    background-color: #ffffff;
    border-radius: 6px;
}

.LesionRecordItem_titlebarContainer__3_mpR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
}

.LesionRecordItem_buttonsContainer__1HcVo {
}

.LesionRecordItem_textTitle__36I_Z {
    font-weight: bold;
}

.LesionRecordItem_textLabel__1kVUh {
    font-weight: bold;
    margin-right: 0.5em;
}

.LesionRecordItem_recordDetails__GMDLu {
    margin-bottom: 30px;
}

.LesionRecordItem_imagesContainer__1H8N- {
}

.LesionRecordItem_imageContainer__IntMF {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.LesionRecordItem_imageContainer__IntMF:nth-child(4n) {
    margin-right: 0px;
}

.Documents_container__1A8t2 {
    padding: 20px 40px 0px 40px;
    margin-bottom: 30px;

    background-color: #ffffff;
    border-radius: 6px;
}

.Documents_titlebarContainer__9H-LP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 30px;
}

.Documents_textTitle__14U-z {
    font-weight: bold;
}

.VisitDetails_controlsContainer__2iEGJ {
    margin-bottom: 120px;
}

.VisitDetails_marginR20__3kF8- {
    margin-right: 20px;
}

.VisitDetails_splitContainer__2kEp3 {
    margin-bottom: 40px;
}

.VisitDetails_splitContainerItem__2trGR {
    flex: 1 0;

    margin-right: 40px;
}

.VisitDetails_splitContainerItem__2trGR:last-child {
    margin-right: 0px;
}

.VisitDetails_label__5wIHa {
    color: var(--color-text);
    margin-bottom: 25px;
    text-indent: 40px;
}

.VisitDetails_uploadButtonsContainer__1G_n9 {
}

.VisitDetails_uploadButton__2-uII {
    position: relative;

    width: 150px;
    height: 150px;

    border-radius: 50%;
}

.VisitDetails_uploadButton__2-uII:hover {
    background-color: var(--color-accent-light);
}

.VisitDetails_uploadButton__2-uII span {
    opacity: 0;
}

.VisitDetails_uploadButton__2-uII:hover span {
    opacity: 1;
    color: var(--contrast-color-accent-light);
}

.VisitDetails_uploadButton__2-uII img {
    position: absolute;

    top: 0;
    left: 0;

    max-height: 100%;
    max-width: 100%;
}

.VisitDetails_uploadButton__2-uII:hover img {
    opacity: 0;
}

.VisitDetails_temporaryInputFields__AQYUu {
    height: 50px;
    border-radius: 6px;
    border: solid 2px rgba(0, 0, 0, 0.33);
    background-color: white;
}

.VisitDetails_select__1Yaey {
    text-indent: 30px;
}

.VisitDetails_datepicker__1ni97 {
    width: 100%;
    text-indent: 37px;
}
.ImageDropzone_container__26uj2 {
    padding: 20px 40px;
    margin-bottom: 30px;

    background-color: rgba(255, 255, 255, 0.5);

    border: dashed 2px #ffffff;
    border-radius: 6px;
}

.ImageDropzone_dzText__wW3An {
    margin-bottom: 20px;
}

.ImageDropzone_imagesContainer__PIEhY {
};
.DocumentImage_container__QBwOE {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.DocumentImage_container__QBwOE:nth-child(4n) {
    margin-right: 0px;
}

.DocumentImage_hidden__WauCm {
    display: none;
}

/* --------------------------------------------------------------------------------------------------------------------------------- overlay stuff -- */

.DocumentImage_overlay__3xL8j {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.DocumentImage_overlayControls__3PxSz {
    cursor: pointer;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.DocumentImage_topBar__zP-EL {

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.DocumentImage_deleteButton__290CD {
    position: absolute;

    width: 30px;
    height: 30px;

    top: 5px;
    right: 10px;

    display: none;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.DocumentImage_overlay__3xL8j:hover .DocumentImage_deleteButton__290CD {
    display: block;
}

.DocumentImage_overlay__3xL8j:hover .DocumentImage_deleteButton__290CD:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

/* ----------------------------------------------------------------------------------------------------------------------------------- image stuff -- */

.DocumentImage_image__VtSZd {
    display: block;
    object-fit: cover;

    width: 100%;
    height: 100%;
}
.DocumentsEditor_controlsContainer__2WeWH {
    margin-bottom: 120px;
}

.DocumentsEditor_button__3impG {
    margin-right: 20px;
}

.DocumentsEditor_button__3impG:last-child {
    margin-right: 0;
}
.LesionRecordEditor_controlsContainer__3XJp5 {
    margin-bottom: 120px;
}

.LesionRecordEditor_button__1DNXW {
    margin-right: 20px;
}

.LesionRecordEditor_button__1DNXW:last-child {
    margin-right: 0;
}

.LesionRecordEditor_label__BYkGe {
    color: var(--color-text);
    margin-bottom: 20px;
    text-indent: 40px;
}

.LesionRecordEditor_textarea__13Kjk {

    width: 100%;

	height: 90px;
    max-height: 300px;

	border: solid 2px var(--color-placeholder);
    border-radius: 6px;

    padding: 20px 40px;
    margin-bottom: 30px;

    font-weight: 700;
    resize: vertical;
    outline: none;
}

.LesionRecordEditor_textarea__13Kjk::-webkit-input-placeholder {
	color: var(--color-placeholder);
}

.LesionRecordEditor_textarea__13Kjk::-moz-placeholder {
	color: var(--color-placeholder);
}

.LesionRecordEditor_textarea__13Kjk::-ms-input-placeholder {
	color: var(--color-placeholder);
}

.LesionRecordEditor_textarea__13Kjk::placeholder {
	color: var(--color-placeholder);
}

.LesionRecordEditor_imageContainer__39D3h {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.LesionRecordEditor_imageContainer__39D3h:nth-child(4n) {
    margin-right: 0px;
}
.Records_searchField__2JZqA {
    margin: 70px 0 20px 0;
}

.Records_recordtList__8jOjD {
    padding: 0 30px;
}

.Records_recordListHeader__3cZD8 {
    margin-bottom: 20px;
}

.Records_recordList__2UafY {
    padding: 0 30px;
}

.Record_controlsContainer__2gp2R {
    margin-bottom: 40px;
}

.Record_button__1YEi- {
    margin-right: 20px;
}

.Record_button__1YEi-:last-child {
    margin-right: 0px;
}

.Record_patientName__1xLgQ {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-indent: var(--screen-title-indent);
}

.Record_patientDescription__p4hho {
    margin-bottom: 40px;
    text-indent: var(--screen-title-indent);
}

.Record_imagesContainer__2w97h {
    padding: 20px 40px;
    margin-bottom: 40px;

    background-color: #ffffff;
    border-radius: 6px;
}

.Record_imagesControls__us7w- {
    margin-bottom: 20px;
}

.Record_text__32xOj {
    margin-bottom: 20px;
}

/* .text:last-child {
    margin-bottom: 120px;
} */

.Record_textLabel__1m3z- {
    font-weight: bold;
    margin-right: 10px;
}
.react-quiz-container {
  /* margin: 20px;
  width: 550px; */

  position: absolute;
  height: calc(100vh - 72px);
  top: 72px;
  overflow: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.react-quiz-home {
  width: 820px;
  margin: 0px auto;
}

.react-quiz-home .inner-container {
  margin: 0px 60px;
}

.react-quiz-home .quiz-length {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.react-quiz-home .quiz-synopsis {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.quiz-restart-button {
  position: fixed;
}

.card-body {
  position: relative;
  max-width: 720px;
}

.react-quiz-container .startQuizWrapper {
  margin-top: 10px;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  border-radius: 4px;
  border: 1px solid #e8e8e8;;
  font-size: 15px;
  display: inline;
  white-space: normal;
  text-align: unset;
}

.nextQuestionBtn {
  position: fixed;
  top: 85px;
  right: 20px;
  min-width: 150px;

  height: 40px;
  margin: 0;
  padding: 0 15px !important;

  border: none;
  border-radius: 6px;

  outline: none;

  font-weight: 700;
  text-transform: none;

  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;
  overflow: hidden;

  background-color: var(--color-accent);
  color: var(--contrast-color-accent);
}

.nextQuestionBtn:hover {
  background-color: var(--color-accent-light);
  color: var(--contrast-color-accent-light);
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;

  /* border: 4px solid green; */
}

.react-quiz-container .questionWrapper .btn.incorrect {
  /* background: red; */
  /* color: white; */

  /* border: 4px solid red; */
}

.questionModal {
  display: none;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
  color: white;
}

.react-quiz-container .answerBtn.correct:hover {
  background: limegreen;
  color: white;
}

.react-quiz-container .correct span {
  color: white;
}

.react-quiz-container .incorrect {
  background: red;
  color: white;
}

.react-quiz-container .answerBtn.incorrect:hover {
  background: coral;
  color: white;
}

.react-quiz-container .incorrect span{
  color: white;
}

.react-quiz-container .questionWrapper {
  display: flex;
  justify-content: center;
}

.react-quiz-container .questionWrapper img {
  width: 100%;
}

.react-quiz-container .questionWrapperBody {
  width: 720px;
}

.questionWrapperBody div:nth-child(2) {
  margin: 20px 0px;
}

.questionWrapperBody h3 {
  padding: 10px 0px;
  font-size: 1.5em;

  display: none;
}

.react-quiz-container .answerBtn {
  width: 50%;
}

.react-quiz-container .answerBtn:hover {
  background-color: white;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 2px;
  line-height: 1.35135;
  color: rgba(0,0,0,0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper h3 {
    background-color: #fafafa;;
    opacity: 0.8;
    color: rgba(0,0,0,0.9);
    margin: 0px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explaination {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
}

/* .react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
} */

.react-quiz-container .quiz-result-filter {
   overflow: hidden;
   width: 120px;
   border-radius: 5px;
   background-color: #fefefe;
   margin-bottom: 10px;
   border: 1px solid #e8e8e8;
}

.react-quiz-container .quiz-result-filter select {
   background: transparent;
   border: none;
   font-size: 16px;
   padding: 5px;
   width: 100%;
   height: 30px;
   border: 1px solid #ffffff;
}

.react-quiz-container .tag-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection{
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #673AB7;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #3F51B5;
}

.react-quiz-container .selection-tag.multiple {
  background: #FF9800;
}

.react-quiz-container .answerBtn_with_Participants {
  display: block;
  width: 200%;
}

.react-quiz-container .otherParticipants {
  display: inline;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  /* color: blue; */
  color: #4400ff;
}

/* ------------------------------------------------------------------------------------------------------------------------------------- variables -- */

:root {
    --screen-header-height: calc(var(--navHeight) + 2px); /* temporary calculation, refactor later */
    --screen-content-height: calc(100vh - var(--screen-header-height));
    --max-width: 820px;
    --screen-title-indent: 40px;

    --color-accent: #4400ff;
    --contrast-color-accent: #fff;
    --color-accent-light: #4400ff33;
    --contrast-color-accent-light: #4400ff;
    --color-interactive: #000;

    --color-placeholder: #00000033;
    --color-background: #EDECF0;

    --color-text: #444;
    --color-text-dark: #000;
}

/* ------------------------------------------------------------------------------------------------------------------------------------- reset css -- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
    border: 0;

	font-size: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #444;
    color: var(--color-text);
    line-height: 1.5em;

	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    line-height: 1;
    background-color: #EDECF0;
    background-color: var(--color-background);
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------------ containers -- */

.App_container__1MQN3 {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
}

.contentContainer {
    display: flex;
    flex-direction: column;

    flex: 1 0;

    width: 100%;
    max-width: 820px;

    margin: 0 auto;
    padding: 60px 0 0 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------- globals: layout -- */

.flexCol {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.inlineFlexCol {
    display: inline-flex;
    flex-direction: column;
}

.inlineFlexRow {
    display: inline-flex;
    flex-direction: row;
}

.alignItemsStart {
    align-items: flex-start;
}

.alignItemsCenter {
    align-items: center;
}

.alignItemsEnd {
    align-items: flex-end;
}

.justifyContentStart {
    justify-content: flex-start;
}

.justifyContentCenter {
    justify-content: center;
}

.justifyContentEnd {
    justify-content: flex-end;
}

.justifyContentSbetween {
    justify-content: space-between;
}

.justifyContentSaround {
    justify-content: space-around;
}

.justifyContentSevenly {
    justify-content: space-evenly;
}

.grow {
    flex-grow: 1;
}

.noShrink {
    flex-shrink: 0;
}

.flexWrap {
    flex-wrap: wrap;
}

.flexNoWrap {
    flex-wrap: nowrap;
}

