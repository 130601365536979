.react-quiz-container {
  /* margin: 20px;
  width: 550px; */

  position: absolute;
  height: calc(100vh - 72px);
  top: 72px;
  overflow: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.react-quiz-home {
  width: 820px;
  margin: 0px auto;
}

.react-quiz-home .inner-container {
  margin: 0px 60px;
}

.react-quiz-home .quiz-length {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.react-quiz-home .quiz-synopsis {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.quiz-restart-button {
  position: fixed;
}

.card-body {
  position: relative;
  max-width: 720px;
}

.react-quiz-container .startQuizWrapper {
  margin-top: 10px;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  border-radius: 4px;
  border: 1px solid #e8e8e8;;
  font-size: 15px;
  display: inline;
  white-space: normal;
  text-align: unset;
}

.nextQuestionBtn {
  position: fixed;
  top: 85px;
  right: 20px;
  min-width: 150px;

  height: 40px;
  margin: 0;
  padding: 0 15px !important;

  border: none;
  border-radius: 6px;

  outline: none;

  font-weight: 700;
  text-transform: none;

  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;
  overflow: hidden;

  background-color: var(--color-accent);
  color: var(--contrast-color-accent);
}

.nextQuestionBtn:hover {
  background-color: var(--color-accent-light);
  color: var(--contrast-color-accent-light);
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;

  /* border: 4px solid green; */
}

.react-quiz-container .questionWrapper .btn.incorrect {
  /* background: red; */
  /* color: white; */

  /* border: 4px solid red; */
}

.questionModal {
  display: none;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
  color: white;
}

.react-quiz-container .answerBtn.correct:hover {
  background: limegreen;
  color: white;
}

.react-quiz-container .correct span {
  color: white;
}

.react-quiz-container .incorrect {
  background: red;
  color: white;
}

.react-quiz-container .answerBtn.incorrect:hover {
  background: coral;
  color: white;
}

.react-quiz-container .incorrect span{
  color: white;
}

.react-quiz-container .questionWrapper {
  display: flex;
  justify-content: center;
}

.react-quiz-container .questionWrapper img {
  width: 100%;
}

.react-quiz-container .questionWrapperBody {
  width: 720px;
}

.questionWrapperBody div:nth-child(2) {
  margin: 20px 0px;
}

.questionWrapperBody h3 {
  padding: 10px 0px;
  font-size: 1.5em;

  display: none;
}

.react-quiz-container .answerBtn {
  width: 50%;
}

.react-quiz-container .answerBtn:hover {
  background-color: white;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 2px;
  line-height: 1.35135;
  color: rgba(0,0,0,0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper h3 {
    background-color: #fafafa;;
    opacity: 0.8;
    color: rgba(0,0,0,0.9);
    margin: 0px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explaination {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
}

/* .react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
} */

.react-quiz-container .quiz-result-filter {
   overflow: hidden;
   width: 120px;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
   background-color: #fefefe;
   margin-bottom: 10px;
   border: 1px solid #e8e8e8;
}

.react-quiz-container .quiz-result-filter select {
   background: transparent;
   border: none;
   font-size: 16px;
   padding: 5px;
   width: 100%;
   height: 30px;
   border: 1px solid #ffffff;
}

.react-quiz-container .tag-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection{
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #673AB7;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #3F51B5;
}

.react-quiz-container .selection-tag.multiple {
  background: #FF9800;
}

.react-quiz-container .answerBtn_with_Participants {
  display: block;
  width: 200%;
}

.react-quiz-container .otherParticipants {
  display: inline;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  /* color: blue; */
  color: #4400ff;
}
