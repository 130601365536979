.controlsContainer {
    margin-bottom: 120px;
}

.button {
    margin-right: 20px;
}

.button:last-child {
    margin-right: 0;
}