.container {

}

.scrollContainer {
    composes: grow noShrink from global;
    /* overflow: auto; */
}

.addItemButton {
    composes: flexRow alignItemsCenter from global;

    width: 100%;
    height: 70px;

    margin-bottom: 20px;
    padding: 0 40px;

    border-radius: 6px;
    border: dashed 2px #ffffff;

    outline: none;

    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.5);

    transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.addItemButton:hover {
    transform: scale(1.02);
}

.svg {
    margin-left: -10px;
}
