.container {
    padding: 20px 40px 0px 40px;
    margin-bottom: 30px;

    background-color: #ffffff;
    border-radius: 6px;
}

.titlebarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
}

.buttonsContainer {
    composes: flexRow from global;
}

.textTitle {
    font-weight: bold;
}

.textLabel {
    font-weight: bold;
    margin-right: 0.5em;
}

.recordDetails {
    margin-bottom: 30px;
}

.imagesContainer {
    composes: flexRow flexWrap from global;
}

.imageContainer {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.imageContainer:nth-child(4n) {
    margin-right: 0px;
}
