.controlsContainer {
    margin-bottom: 120px;
}

.button {
    margin-right: 20px;
}

.button:last-child {
    margin-right: 0;
}

.label {
    color: var(--color-text);
    margin-bottom: 20px;
    text-indent: 40px;
}

.textarea {
    composes: noShrink from global;

    width: 100%;

	height: 90px;
    max-height: 300px;

	border: solid 2px var(--color-placeholder);
    border-radius: 6px;

    padding: 20px 40px;
    margin-bottom: 30px;

    font-weight: 700;
    resize: vertical;
    outline: none;
}

.textarea::placeholder {
	color: var(--color-placeholder);
}

.imageContainer {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.imageContainer:nth-child(4n) {
    margin-right: 0px;
}