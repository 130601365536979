.container {
    padding: 20px 40px;
    margin-bottom: 30px;

    background-color: rgba(255, 255, 255, 0.5);

    border: dashed 2px #ffffff;
    border-radius: 6px;
}

.dzText {
    margin-bottom: 20px;
}

.imagesContainer {
    composes: flexRow flexWrap from global;
};