.controlsContainer {
    margin-bottom: 120px;
}

.marginR20 {
    margin-right: 20px;
}

.splitContainer {
    composes: flexRow from global;
    margin-bottom: 40px;
}

.splitContainerItem {
    composes: flexCol from global;
    flex: 1 0 0%;

    margin-right: 40px;
}

.splitContainerItem:last-child {
    margin-right: 0px;
}

.label {
    color: var(--color-text);
    margin-bottom: 25px;
    text-indent: 40px;
}

.uploadButtonsContainer {
    composes: flexRow justifyContentSbetween from global;
}

.uploadButton {
    position: relative;

    width: 150px;
    height: 150px;

    border-radius: 50%;
}

.uploadButton:hover {
    background-color: var(--color-accent-light);
}

.uploadButton span {
    opacity: 0;
}

.uploadButton:hover span {
    opacity: 1;
    color: var(--contrast-color-accent-light);
}

.uploadButton img {
    position: absolute;

    top: 0;
    left: 0;

    max-height: 100%;
    max-width: 100%;
}

.uploadButton:hover img {
    opacity: 0;
}

.temporaryInputFields {
    height: 50px;
    border-radius: 6px;
    border: solid 2px rgba(0, 0, 0, 0.33);
    background-color: white;
}

.select {
    composes: temporaryInputFields;
    text-indent: 30px;
}

.datepicker {
    composes: temporaryInputFields;
    width: 100%;
    text-indent: 37px;
}