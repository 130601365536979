@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/* TODO: HAX, find a better solution later */
.react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}

:root {
  --accent: #4400ff;
  --background: #EDECF0;
  --hover: #E1DFE6;
  --placeholder: rgba(0,0,0,.33);
  --interactive: #000;
  --text: #444;
  --navHeight: 70px;
  --fontSize: 16px;
}

body {
  background-color: var(--background);
  font-family: 'Montserrat', sans-serif;
  font-size: var(--fontSize);
  font-weight: 400;
}

/* Buttons */

/* button {
  font-weight: 700;
  background-color: var(--accent);
  color: #fff;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
}

button:disabled {
  background-color: transparent;
  color: #000;
  cursor: not-allowed;
}

button:focus, input:focus, textarea:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px var(--hover);
}

button.secondary {
  color: var(--accent);
  background-color: transparent;
}

button.secondary:hover {
  background-color: var(--hover);
} */

.dh-container {
    width: 100%;
    max-width: 760px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

#container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#container a {
  margin: .5em;
}
#container a:first-of-type {
  margin-left: 1em;
}
#container a:last-of-type {
  margin-right: 1em;
}
#spacer {
  flex: 1;
}

/* search & patient search */

.search {
  border: none;
  width: 800px;
  line-height: 70px;
  background-color: #fff;
  border-radius: 35px;
  text-indent: 70px;
}

.search::placeholder {
  text-indent: calc(50% - 70px);
  transition: text-indent 200ms ease-out;
  color: var(--placeholder);
}

.search:focus::placeholder {
  text-indent: inherit;
  color: rgba(0,0,0,.2);
}

/* Breadcrumbs */

.breadcrumb-item:only-of-type {
  display: none;
}

/* Patient list representation */

#patients, #records {
  width: 100%;
}

#empty {
  margin-top: 120px;
  margin-left: 70px;
  color: var(--text);
}

#patients a:hover {
  text-decoration: none;
}

#patients ol {
  list-style: none;
  width: 800px;
  padding: 0;
  margin: 0;
  margin-top: calc(var(--navHeight) + 50px);
}

.patient {
  background-color: #fff;
  border-radius: 5px;
  width: 730px;
  min-height: 70px;
  margin-left: 30px;
  margin-bottom: 20px;
  padding: 20px 110px 20px 40px;
  box-sizing: border-box;
  transition: transform 200ms ease-out;
}

.patient:hover {
  transform: scale(1.03);
}

.patient h2 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
  margin: 0;
}

.patient p {
  color: var(--text);
  margin: 0;
}

/* Patients search bar */

.prominent-search {
  position: absolute;
  margin: 0 auto;
}

.prominent-search button {
  position: absolute;
  top: 15px;
  right: 40px;
}

.prominent-search .icon {
  position: absolute;
  left: 30px;
  top: 20px;
}

/* NavBar */

.navbar {
  padding: 0;
}

.navbar nav {
  display: grid;
  width: 100%;
  grid-template-columns: var(--navHeight) 1fr max-content;
  grid-auto-rows: var(--navHeight);
  background-color: #fff;
  padding: 0;
  box-shadow: 0 0 8px 0 #ccc;
  user-select: none;
  border-bottom: solid 2px transparent;
}

.navbar-nav {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.nav-item {
  flex-grow: 1;
  text-align: center;
  height: var(--navHeight);
}

.navbar-brand {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}

.navbar-brand {
  margin: 0 auto;
}

.navbar-collapse {
  margin: 15px;
  grid-column: 3;
}

/* tabber */

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
  box-sizing: border-box;
}

.nav-item a {
  color: var(--interactive);
  width: 100%;
  text-decoration: none;
}

.nav-item:hover {
  background-color: var(--hover);
}

.nav-item a.active {
  color: var(--accent);
  border-bottom: solid 2px var(--accent);
}

.nav-item a.active:hover {
  background-color: transparent;
}

/* breadcrumbs */

.breadcrumb  {
  margin-top: 50px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: .75em;
}

.breadcrumb a {
  color: black;
}

.breadcrumb span.active {
  color: var(--accent);
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #95a5a6;
    content: "›";
}

/* centering */

#loggedInUser, .navbar-brand, .nav-item {
  display: flex;
  align-items: center;
  text-align: center;
}

.navbar-brand {
  justify-content: center;
}

.navbar-brand, .nav-item a, .username {
  line-height: var(--navHeight);
}

/* Logged in component*/

#loggedInUser {
  margin-right: 30px;
}

.userimage {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fafafa;
  background-size: cover;
  margin: 0 10px;
  background-position: center;
}

.username {
  cursor: pointer;
  padding: 0;
}

#loggedInUser:hover {
  background-color: var(--hover);
}

.account-dropdown {
  position: absolute;
  right: 30px;
  top: var(--navHeight);
  padding: 0;
  background-color: #fff;
  box-shadow: 0 12px -2px 0 #ccc;
  overflow: hidden;
  transform-origin: top;
  transition: transform 150ms ease-out;
}

.account-dropdown li {
  list-style: none;
  background-color: #fff;
  width: auto;
  padding: 20px 60px;
  cursor: pointer;
}

.account-dropdown li:hover {
  background-color: var(--hover);
}

.account-dropdown.collapsed {
  transform: scaleY(0);
  pointer-events: none;
}

#loggedInUser .icon {
  transition: transform 150ms ease-out;
}

#loggedInUser.collapsed .icon {
  transform: rotate(180deg);
}

/* Modal */

#newPatientForm label {
  margin-top: 30px;
  display: block;
}

#newPatientForm textarea, #newPatientForm input[type="text"] {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 30px 30px;
}

#newPatientForm input[type="text"] {
  height: 70px;
}

#newPatientForm .actions {
  text-align: center;
}

#newPatientForm button {
  margin: 20px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  background-color: var(--background) !important;
  padding: 40px !important;
  /* DEV Note - KILL REACT MODAL */
}

/* Records table */

.react-bs-table-container {
  width: 100%;
  margin-top: 140px;
}

/* Image upload quick hack - to be refined */

#imageUpload button:disabled {
  background-color: var(--hover);
}

#newLesion {
  margin-top: 40px;
}

#react-quiz-container .questionWrapper .btn {
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 2px;
  border: 1px solid #e8e8e8;;
  font-size: 12px;
  display: block;
  white-space: normal;
  text-align: unset;
  padding: 5px 6px;
}

#react-quiz-container .selection-tag,
#react-quiz-container .number-of-selection{
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
}

#react-quiz-container .questionWrapper .btn.nextQuestionBtn {
  background: blue;
  color: white;
}
