.container {
    margin-bottom: 20px;
    padding: 20px 40px;

    border-radius: 6px;
    background-color: #ffffff;

    text-decoration: none;
}

.nameText {
    font-weight: bold;
    color: var(--color-text-dark);
    margin-bottom: 10px;
}

.descriptionText {
    color: var(--color-text);
}

.link {
    display: block;
    text-decoration: none;
    color: currentColor;

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.link:hover {
    text-decoration: none;
    color: currentColor;

    transform: scale(1.02);
}