.controlsContainer {
    margin-bottom: 100px;
}

.searchField {
    margin-bottom: 20px;
}

.recordListHeader {
    margin-bottom: 20px;
}

.recordList {
    padding: 0 30px;
}
