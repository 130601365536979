.controlsContainer {
    margin-bottom: 40px;
}

.button {
    margin-right: 20px;
}

.button:last-child {
    margin-right: 0px;
}

.patientName {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-indent: var(--screen-title-indent);
}

.patientDescription {
    margin-bottom: 40px;
    text-indent: var(--screen-title-indent);
}

.imagesContainer {
    padding: 20px 40px;
    margin-bottom: 40px;

    background-color: #ffffff;
    border-radius: 6px;
}

.imagesControls {
    composes: flexRow justifyContentEnd from global;
    margin-bottom: 20px;
}

.text {
    margin-bottom: 20px;
}

/* .text:last-child {
    margin-bottom: 120px;
} */

.textLabel {
    font-weight: bold;
    margin-right: 10px;
}