.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.image {
    display: block;
    object-fit: cover;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.image:nth-child(4n) {
    margin-right: 0px;
}