.searchField {
    margin: 70px 0 20px 0;
}

.recordtList {
    composes: flexCol grow noShrink from global;
    padding: 0 30px;
}

.recordListHeader {
    margin-bottom: 20px;
}

.recordList {
    padding: 0 30px;
}
