.container {
    position: relative;

    width: 22.1875%;
    height: 150px;

    margin-right: 3.75%;
    margin-bottom: 30px;
}

.container:nth-child(4n) {
    margin-right: 0px;
}

.hidden {
    display: none;
}

/* --------------------------------------------------------------------------------------------------------------------------------- overlay stuff -- */

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.overlayControls {
    composes: flexCol justifyContentSbetween from global;
    composes: overlay;
    cursor: pointer;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.topBar {
    composes: flexRow alignItemsCenter from global;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.deleteButton {
    position: absolute;

    width: 30px;
    height: 30px;

    top: 5px;
    right: 10px;

    display: none;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.overlay:hover .deleteButton {
    display: block;
}

.overlay:hover .deleteButton:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

/* ----------------------------------------------------------------------------------------------------------------------------------- image stuff -- */

.image {
    display: block;
    object-fit: cover;

    width: 100%;
    height: 100%;
}