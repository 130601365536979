.overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ progress overlay -- */

.overlayProgress {
    composes: flexCol justifyContentCenter alignItemsCenter from global;
    composes: overlayContainer;
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ controls overlay -- */

.overlayControls {
    composes: flexCol justifyContentSbetween from global;
    composes: overlayContainer;
    cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.label {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.topBar {
    composes: flexRow alignItemsCenter from global;

    padding: 5px 10px;
    width: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.bottomBar {
    composes: flexRow alignItemsCenter from global;

    padding: 5px 10px;
    width: 100%;
}
