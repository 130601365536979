.container {
    padding: 20px 40px 0px 40px;
    margin-bottom: 30px;

    background-color: #ffffff;
    border-radius: 6px;
}

.titlebarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 30px;
}

.textTitle {
    font-weight: bold;
}
