/* TODO: Currently unused. I left it here until we agree on scroll behaviour */
.container {
    composes: flexCol from global;

    height: var(--screen-content-height);
    max-height: var(--screen-content-height);
    width:  100%;
    max-width: var(--max-width);

    margin: 0 auto;
    overflow: auto;
}

.outerContainer {
    height: var(--screen-content-height);
    max-height: var(--screen-content-height);
    width:  100%;
    overflow: auto;
}

.innerContainer {
    composes: flexCol grow from global;
    max-width: var(--max-width);
    margin: 0 auto;
}