.variables {
	--timing: 200ms;
	--easing: cubic-bezier(0.165, 0.84, 0.44, 1);
	--hover: .33;
}

.container {
    composes: flexRow alignItemsCenter from global;
    composes: variables;
    position: relative;
}

.checkbox {
    position: absolute;
    left: -9999px;
}

.label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 30px;
    display: inline-block;
    font-weight: bold;
}

.label::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 8px;
    width: 14px;
    height: 14px;
   	box-shadow: 0 0 0 2px var(--color-interactive) inset;
    border-radius: 100%;
}

.checkbox:checked + .label::after {
	box-shadow: 0 0 0 4px var(--color-accent) inset;
}

.label:hover,
.checkbox:focus + .label {
	color: var(--color-accent);
}

.label::before {
	display: inline-block;
	position: absolute;
	top: 5px;
	left: 3px;
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: var(--color-accent);
	opacity: 0;
}

.checkbox:not(:checked)

.checkbox:not(:checked) + .label:hover:after,
.checkbox:not(:checked):focus + .label:after {
	box-shadow: 0 0 0 2px var(--color-accent) inset;
}

.label:hover:before,
.checkbox:focus + .label:before {
	opacity: var(--hover);
}

.label::after,
.label::before,
.label {
	transition-duration: var(--timing);
	transition-timing-function: var(--easing);
}

.label:after {
	transition-property: box-shadow;
}

.label:before {
	transition-property: opacity;
}

.label {
	transition-property: color;
}