.container {
    position: fixed;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999999;
}

.image {
    max-width: 80vw;
    max-height: 80vh;
}

.button {
    position: absolute;
    min-width: 50px;
    min-height: 50px;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.nextButton {
    composes: button;
    right: 20px;
    transform: rotate(180deg);
}

.prevButton {
    composes: button;
    left: 20px;
}

.closeButton {
    composes: button;
    right: 20px;
    top: 20px;
}