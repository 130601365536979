@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/* ------------------------------------------------------------------------------------------------------------------------------------- variables -- */

:root {
    --screen-header-height: calc(var(--navHeight) + 2px); /* temporary calculation, refactor later */
    --screen-content-height: calc(100vh - var(--screen-header-height));
    --max-width: 820px;
    --screen-title-indent: 40px;

    --color-accent: #4400ff;
    --contrast-color-accent: #fff;
    --color-accent-light: #4400ff33;
    --contrast-color-accent-light: #4400ff;
    --color-interactive: #000;

    --color-placeholder: #00000033;
    --color-background: #EDECF0;

    --color-text: #444;
    --color-text-dark: #000;
}

/* ------------------------------------------------------------------------------------------------------------------------------------- reset css -- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
    border: 0;

	font-size: 100%;
    font-family: 'Montserrat', sans-serif;
    color: var(--color-text);
    line-height: 1.5em;

	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    line-height: 1;
    background-color: var(--color-background);
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------------ containers -- */

.container {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
}

:global .contentContainer {
    display: flex;
    flex-direction: column;

    flex: 1 0 0%;

    width: 100%;
    max-width: 820px;

    margin: 0 auto;
    padding: 60px 0 0 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------- globals: layout -- */

:global .flexCol {
    display: flex;
    flex-direction: column;
}

:global .flexRow {
    display: flex;
    flex-direction: row;
}

:global .inlineFlexCol {
    display: inline-flex;
    flex-direction: column;
}

:global .inlineFlexRow {
    display: inline-flex;
    flex-direction: row;
}

:global .alignItemsStart {
    align-items: flex-start;
}

:global .alignItemsCenter {
    align-items: center;
}

:global .alignItemsEnd {
    align-items: flex-end;
}

:global .justifyContentStart {
    justify-content: flex-start;
}

:global .justifyContentCenter {
    justify-content: center;
}

:global .justifyContentEnd {
    justify-content: flex-end;
}

:global .justifyContentSbetween {
    justify-content: space-between;
}

:global .justifyContentSaround {
    justify-content: space-around;
}

:global .justifyContentSevenly {
    justify-content: space-evenly;
}

:global .grow {
    flex-grow: 1;
}

:global .noShrink {
    flex-shrink: 0;
}

:global .flexWrap {
    flex-wrap: wrap;
}

:global .flexNoWrap {
    flex-wrap: nowrap;
}
