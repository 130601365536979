.headerContainer {
    composes: flexRow alignItemsCenter from global;

    min-height: 30px;
    padding: 0 40px;

    font-weight: bold;
}

.link {
    display: block;
    text-decoration: none;
    color: currentColor;

    transition:
        transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.link:hover {
    text-decoration: none;
    color: currentColor;

    transform: scale(1.02);
}

.itemContainer {
    composes: flexRow alignItemsCenter from global;

    margin-bottom: 20px;
    padding: 20px 40px;

    border-radius: 6px;
    background-color: #ffffff;

    text-decoration: none;
}

.col {
    margin-right: 20px;
}

.col:last-child {
    margin-right: 0;
}

.colS {
    composes: col;
    width: 120px;
}

.colIcon {
    composes: flexRow justifyContentEnd alignItemsCenter from global;
    width: 120px;
}

.colGrow {
    composes: col;
    flex: 1 0 0%;
}

.svg {
    fill: currentColor;
}