.container {
    position: relative;

    max-width: 80vw;
    max-height: 80vh;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.label {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

.labelFat {
    composes: label;

    font-size: 14px;
    padding: 4px 6px;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.topBar {
    composes: flexRow alignItemsCenter from global;

    position: absolute;
    top: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

.prediction {
    color: white;
    margin-right: 20px;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.bottomBar {
    composes: flexRow alignItemsCenter from global;

    position: absolute;
    bottom: -35px;

    padding: 0px 10px;

    width: 100%;
    height: 30px;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.wrappedImage {
    max-width: 80vw;
    max-height: 80vh;
}