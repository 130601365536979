.overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ------------------------------------------------------------------------------------------------------------------------------ progress overlay -- */

.overlayProgress {
    composes: flexCol justifyContentCenter alignItemsCenter from global;
    composes: overlayContainer;
    background-color: rgba(255, 255, 255, 0.8);
}

/* ------------------------------------------------------------------------------------------------------------------------------ controls overlay -- */

.overlayControls {
    composes: flexCol justifyContentSbetween from global;
    composes: overlayContainer;
    cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------------------------------- labels stuff -- */
/* TODO: move label to a common place for imageItem specific css later */

.label {
    border-radius: 4px;
    padding: 2px 5px;
    margin-right: 10px;

    font-size: 12px;
    background-color: #ffffff;

    opacity: 0.8;
}

.label:hover {
    opacity: 0.8;
}

.labelInActive {
    composes: label;
    opacity: 0.4;
}

/* --------------------------------------------------------------------------------------------------------------------------------- top bar stuff -- */

.topBar {
    composes: flexRow alignItemsCenter from global;

    padding: 5px 10px;
    width: 100%;
    min-height: 30px;
}

.deleteButton {
    position: absolute;

    width: 30px;
    height: 30px;

    top: 5px;
    right: 10px;

    display: none;

    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
}

.overlayContainer:hover .deleteButton {
    display: block;
}

.overlayContainer:hover .deleteButton:hover {
    color: var(--color-text);
    background-color: rgba(255, 255, 255, 0.9);
}

.svg {
    fill: currentColor;
}

.prediction {
    composes: label;
}

.overlayContainer:hover .prediction {
    display: none;
}

/* ------------------------------------------------------------------------------------------------------------------------------ bottom bar stuff -- */

.bottomBar {
    composes: flexRow alignItemsCenter from global;

    padding: 5px 10px;

    width: 100%;
}
